<template>
<div>
    <div class="md-layout">
        <div class="md-layout-item md-size-50">
            <md-field>
                <label>Description(KO)</label>
                <md-textarea v-model="docent.script"></md-textarea>
                <md-icon>description</md-icon>
            </md-field>
        </div>
        <div class="md-layout-item md-size-50">
            <md-field>
                <label>Description(EN)</label>
                <md-textarea v-model="checkSubLang.eng.script"></md-textarea>
                <md-icon>description</md-icon>
            </md-field>
        </div>
        <div class="md-layout-item md-size-50">
            <md-field>
                <label>Description(CN)</label>
                <md-textarea v-model="checkSubLang.cn.script"></md-textarea>
                <md-icon>description</md-icon>
            </md-field>
        </div>
        <div class="md-layout-item md-size-50">
            <md-field>
                <label>Description(JP)</label>
                <md-textarea v-model="checkSubLang.jp.script"></md-textarea>
                <md-icon>description</md-icon>
            </md-field>
        </div>
        
    </div>
    <div class="edit-form md-layout md-alignment-center-right">
        <div class="md-layout-item md-size-10">
            <md-button @click="updateDocent" class="md-dense md-raised md-primary">저장</md-button>
        </div>
    </div>
</div>
</template>

<script>
import axios from "axios";
import api from "@/commons/apiUrl";

export default {
    props: {
        docent: Object
    },
    data() {
        return {
            langType: "ko",
            data: this.docent,
            subLang: {
                eng: {
                    title: "",
                    description: ""
                },
                cn: {
                    title: "",
                    description: ""
                },
                jp: {
                    title: "",
                    description: ""
                }
            }
        }
    },
    computed: {
        checkSubLang() {
            if(this.docent.subLang) {
                const checkList = ["eng", "cn", "jp"];
                checkList.forEach((prop) => {
                    if(Object.hasOwnProperty.bind(this.docent.subLang)(prop)) {
                        this.subLang[prop] = this.docent.subLang[prop];
                    }
                });
            }

            return this.subLang;
        }
    },
    methods: {
        async updateDocent() {
            const data = new FormData();

            data.append("engScript", this.subLang.eng.script);
            data.append("cnScript", this.subLang.cn.script);
            data.append("jpScript", this.subLang.jp.script);

            data.append("script", this.docent.script);
            
            const res = await axios.put(api.UPDATE_DOCENT(this.docent.sceneId),
                data,
                { 
                    headers: { 
                        authorization: this.$store.getters.getToken,
                    }
                }
            );

            if(!res.data.success) {
                this.$store.dispatch("alerts/error", "저장 실패");

                return;
            }

            this.$store.dispatch("alerts/success", "저장 성공");
        }
    }
}
</script>